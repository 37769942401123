// useMessageHandling.js
import { useState, useRef, useCallback, useEffect } from 'react';
import { useApp } from '../../../contexts/AppContext';
import { useAuth } from '../../../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import { baseSystemPrompt } from '../../../config/characters';

const RAG_THRESHOLD = 40; // Switch to RAG after 40 messages

export function useMessageHandling(isGameActive, currentPersonality) {
  const location = useLocation();
  const {
    messages,
    setMessages,
    messageCount,
    setMessageCount,
    gameMode,
    setIsGameActive,
    analyzeAndUpdateLifeBar,
    aiService: appAIService
  } = useApp();
  
  const { user, authenticatedFetch } = useAuth();

  const [inputText, setInputText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState(null);

  const startTime = useRef(Date.now());
  const messageIdRef = useRef(Date.now());
  const streamTimeoutRef = useRef(null);
  const currentMessagesRef = useRef(messages);

  // Keep messages ref in sync
  useEffect(() => {
    currentMessagesRef.current = messages;
  }, [messages]);

  const debugLog = (message, data = {}) => {
    console.group(`💬 useMessageHandling: ${message}`);
    console.log('State:', {
      isGameActive,
      messageCount,
      gameMode,
      isTyping,
      hasError: !!error,
      hasPersonality: !!currentPersonality,
      hasAIService: !!appAIService,
      personaName: currentPersonality?.name,
      currentMessages: messages,
      usingRAG: messageCount > RAG_THRESHOLD
    });
    if (Object.keys(data).length > 0) {
      console.log('Data:', data);
    }
    console.groupEnd();
  };

  const getNextMessageId = useCallback(() => {
    messageIdRef.current += 1;
    return messageIdRef.current;
  }, []);

  // Store message in RAG system
  const storeMessageInRAG = async (message, isAI = false) => {
    try {
      await authenticatedFetch('/api/chat/memory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: user.id,
          characterId: currentPersonality.name,
          messageId: message.id,
          content: message.text,
          metadata: {
            role: isAI ? 'assistant' : 'user',
            timestamp: new Date().toISOString()
          }
        })
      });
    } catch (error) {
      console.error('Error storing message in RAG:', error);
      // Don't throw - we want to continue even if RAG storage fails
    }
  };

  // Get context-aware prompt
  const getContextAwarePrompt = async (userMessage) => {
    try {
      const response = await authenticatedFetch('/api/chat/context', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: user.id,
          characterId: currentPersonality.name,
          currentMessage: userMessage
        })
      });

      if (!response.ok) {
        throw new Error('Failed to get context');
      }

      const data = await response.json();
      return data.prompt;
    } catch (error) {
      console.error('Error getting context:', error);
      // Fall back to base prompt if context retrieval fails
      return baseSystemPrompt;
    }
  };

  const handleSendMessage = useCallback(async () => {
    if (!isGameActive || !inputText.trim() || !currentPersonality || !appAIService) {
      debugLog('Cannot send message', {
        isGameActive,
        hasInput: !!inputText.trim(),
        hasPersonality: !!currentPersonality,
        hasAIService: !!appAIService
      });
      return;
    }

    const currentExchangeCount = Math.floor(messageCount / 2);
    
    if (gameMode === 'classic' && currentExchangeCount >= 6) {
      debugLog('Classic mode message limit reached', {
        messageCount,
        exchangeCount: currentExchangeCount
      });
      setError('Message limit reached');
      setIsGameActive(false);
      return;
    }

    setError(null);
    setIsTyping(true);

    const userMessageText = inputText.trim();
    setInputText('');

    try {
      // Create user message
      const userMessage = {
        id: getNextMessageId(),
        text: userMessageText,
        sender: 'user',
      };

      // Save user message to match if in match mode
      if (location.state?.matchId) {
        try {
          const response = await authenticatedFetch(`/api/matches/${location.state.matchId}/messages`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              content: userMessageText,
              isAI: false
            })
          });
          const data = await response.json();
          userMessage.id = data.message.id;
        } catch (error) {
          console.error('Error saving message to match:', error);
          setError('Failed to send message. Please try again.');
          return;
        }
      }

      // Add user message to state
      const updatedMessages = [...currentMessagesRef.current, userMessage];
      setMessages(updatedMessages);
      currentMessagesRef.current = updatedMessages;

      // Store user message in RAG if past threshold
      if (messageCount >= RAG_THRESHOLD) {
        await storeMessageInRAG(userMessage, false);
      }

      let aiResponse = '';
      const userName = user?.name || 'there';

      // Create AI message placeholder
      const aiMessage = {
        id: getNextMessageId(),
        text: '',
        sender: 'ai',
        character: currentPersonality.name,
      };

      // Add AI message placeholder
      const messagesWithAi = [...currentMessagesRef.current, aiMessage];
      setMessages(messagesWithAi);
      currentMessagesRef.current = messagesWithAi;

      // Get context-aware prompt if using RAG
      const systemPrompt = messageCount >= RAG_THRESHOLD
        ? await getContextAwarePrompt(userMessageText)
        : `${baseSystemPrompt}\n\nYou are ${currentPersonality.name}, ${currentPersonality.personality}\n\nThis is a text message conversation on a dating app. You matched with ${userName}. Be completely authentic to your personality and current emotional state. NEVER use asterisks or describe actions - just text normally like a real person would.`;

      // Get AI response
      await appAIService.streamChat(
        [
          {
            role: 'system',
            content: systemPrompt,
          },
          ...updatedMessages.map((msg) => ({
            role: msg.sender === 'user' ? 'user' : 'assistant',
            content: msg.text,
          }))
        ],
        (token) => {
          aiResponse += token;
          
          // Update AI message text
          if (aiResponse.trim()) {
            const updatedAiMessage = { ...aiMessage, text: aiResponse };
            const newMessages = currentMessagesRef.current.map(msg => 
              msg.id === aiMessage.id ? updatedAiMessage : msg
            );
            setMessages(newMessages);
            currentMessagesRef.current = newMessages;
          }
        }
      );

      // Store AI message in RAG if past threshold
      if (messageCount >= RAG_THRESHOLD) {
        const finalAiMessage = { ...aiMessage, text: aiResponse };
        await storeMessageInRAG(finalAiMessage, true);
      }

      // Save final AI message to match if in match mode
      if (location.state?.matchId && aiResponse.trim()) {
        try {
          const response = await authenticatedFetch(`/api/matches/${location.state.matchId}/messages`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              content: aiResponse,
              isAI: true
            })
          });
          const data = await response.json();
          const finalAiMessage = {
            id: data.message.id,
            text: aiResponse,
            sender: 'ai',
            character: currentPersonality.name,
          };
          const finalMessages = currentMessagesRef.current.map(msg => 
            msg.id === aiMessage.id ? finalAiMessage : msg
          );
          setMessages(finalMessages);
          currentMessagesRef.current = finalMessages;
        } catch (error) {
          console.error('Error saving AI message to match:', error);
        }
      }

      debugLog('AI response generated successfully', { aiResponse });

      // Increment message count after both user and AI messages
      const newCount = messageCount + 2;
      setMessageCount(newCount);

      // Analyze response for life bar mode
      if (gameMode === 'lifeBar') {
        debugLog('Analyzing response for life bar', { aiResponse, userMessage: userMessageText });
        await analyzeAndUpdateLifeBar(aiResponse, userMessageText);
      }

      // For classic mode, just update game state
      if (gameMode === 'classic' && Math.floor(newCount / 2) >= 7) {
        debugLog('Classic mode ending - exchange limit reached', {
          messageCount: newCount,
          exchangeCount: Math.floor(newCount / 2)
        });
        setIsGameActive(false);
      }

    } catch (error) {
      console.error('Error sending message:', error);
      debugLog('Message sending failed', {
        error: error.message,
        stack: error.stack
      });
      setError('Failed to send message. Please try again.');
      setMessages(currentMessagesRef.current); // Revert to original messages
    } finally {
      setIsTyping(false);
      if (streamTimeoutRef.current) {
        clearTimeout(streamTimeoutRef.current);
      }
    }
  }, [
    inputText,
    isGameActive,
    messageCount,
    gameMode,
    currentPersonality,
    appAIService,
    analyzeAndUpdateLifeBar,
    getNextMessageId,
    setMessages,
    user,
    setIsGameActive,
    location.state?.matchId,
    authenticatedFetch
  ]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage();
      }
    },
    [handleSendMessage]
  );

  return {
    inputText,
    setInputText,
    isTyping,
    error,
    setError,
    handleSendMessage,
    handleKeyPress,
    messageCount,
  };
}

export default useMessageHandling;
