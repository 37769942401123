// ChatScreen.jsx
import React, { useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import { useApp } from '../../contexts/AppContext';
import ChatHeader from './ChatHeader';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import ChatError from './ChatError';
import LoadingScreen from './LoadingScreen';
import VoiceChat from './VoiceChat';
import GameHUD from '../GameHUD';
import { useLocation } from 'react-router-dom';

import { useGameLogic } from './hooks/useGameLogic';
import { useVoiceChat } from './hooks/useVoiceChat';
import { useMessageHandling } from './hooks/useMessageHandling';
import { useInitialization } from './hooks/useInitialization';
import { chatStyle } from '../../config/characters';

const ChatScreen = () => {
  const location = useLocation();
  const { 
    chatMode, 
    isGameActive, 
    gameMode,
    isCalculatingScore,
    aiService: appAIService,
    messages
  } = useApp();

  // Only initialize voice chat when in voice mode
  const voiceChatHook = useMemo(() => chatMode === 'voice' ? useVoiceChat(chatMode, appAIService) : {
    isVoiceMode: false,
    isAssistantSpeaking: false,
    volumeLevel: 0,
    error: null,
    isInitialized: false,
    vapiRef: { current: null },
    reinitialize: () => {}
  }, [chatMode, appAIService]);

  const { 
    isVoiceMode, 
    isAssistantSpeaking, 
    volumeLevel, 
    error: voiceError,
    isInitialized,
    vapiRef,
    reinitialize
  } = voiceChatHook;

  const {
    serviceStatus,
    error: initError,
    setError: setInitError,
    isTyping: initTyping,
    messagesEndRef,
    currentPersonality,
    isGenerating,
  } = useInitialization();

  const {
    lifeBar,
    analyzeAndUpdateLifeBar,
    updateLastResponseTime,
  } = useGameLogic();

  const {
    inputText,
    setInputText,
    handleKeyPress,
    handleSendMessage: baseHandleSendMessage,
    isTyping: messageTyping,
    messageCount,
    error: messageError,
  } = useMessageHandling(isGameActive, currentPersonality);

  // Wrap handleSendMessage to update last response time
  const handleSendMessage = async () => {
    await baseHandleSendMessage();
    updateLastResponseTime();
  };

  // Combine typing states
  const isTyping = initTyping || messageTyping;

  // Auto-scroll effect
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Show loading screen while service is initializing
  if (serviceStatus !== 'ready') {
    return <LoadingScreen status={serviceStatus} />;
  }

  // Show loading screen while waiting for profile (only in regular chat mode)
  // or when generating new profiles after running out
  if ((!currentPersonality && !location.state?.matchId) || isGenerating) {
    return (
      <LoadingScreen 
        status="generating" 
        message={isGenerating ? "Generating new profiles..." : "Finding someone to chat with..."} 
        hasRetry={false}
      />
    );
  }

  // Show loading screen while calculating score
  if (isCalculatingScore) {
    return (
      <LoadingScreen 
        status="calculating" 
        message="Calculating your Rizz score..." 
        hasRetry={false}
      />
    );
  }

  // Show loading screen while initializing voice chat
  if (chatMode === 'voice' && !isInitialized && gameMode !== 'lifebar') {
    return (
      <LoadingScreen 
        status="initializing" 
        message={voiceError || "Initializing voice chat..."}
        hasRetry={true}
        onRetry={reinitialize}
      />
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`h-screen bg-gradient-to-b ${chatStyle.gradient} flex flex-col relative`}
    >
      <GameHUD currentLifeBar={lifeBar} />
      <ChatHeader type={chatStyle.type} />

      <ChatMessages
        messages={messages}
        isTyping={isTyping}
        chatMode={chatMode}
        messagesEndRef={messagesEndRef}
      />

      {chatMode === 'text' ? (
        <ChatInput
          inputText={inputText}
          setInputText={setInputText}
          handleKeyPress={handleKeyPress}
          messageCount={messageCount}
          isTyping={isTyping}
          handleSendMessage={handleSendMessage}
          disabled={!isGameActive || isCalculatingScore}
        />
      ) : (
        <VoiceChat
          vapiRef={vapiRef}
          isVoiceMode={isVoiceMode}
          isAssistantSpeaking={isAssistantSpeaking}
          volumeLevel={volumeLevel}
          type={chatStyle.type}
          disabled={!isGameActive || isCalculatingScore}
          error={voiceError}
          isInitialized={isInitialized}
          reinitialize={reinitialize}
        />
      )}

      <ChatError
        error={initError || voiceError || messageError}
        setError={setInitError}
      />
    </motion.div>
  );
};

export default React.memo(ChatScreen);
