import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ChevronLeft, LogOut, LayoutDashboard } from 'lucide-react';

const SettingsScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, authenticatedFetch, logout, updateUser } = useAuth();
  const [interestedIn, setInterestedIn] = useState(user?.interested_in || '');
  const [minAge, setMinAge] = useState(user?.min_age_preference || 21);
  const [maxAge, setMaxAge] = useState(user?.max_age_preference || 35);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const updatePreferences = async () => {
    try {
      setSaving(true);
      setError(null);

      const response = await authenticatedFetch('/api/user/update-preferences', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          interested_in: interestedIn,
          min_age_preference: minAge,
          max_age_preference: maxAge
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to update preferences');
      }

      // Update user context with new preferences
      if (data.user) {
        updateUser(data.user);
      }

      // Check if any preferences changed
      const preferencesChanged = 
        user?.interested_in !== interestedIn ||
        user?.min_age_preference !== minAge ||
        user?.max_age_preference !== maxAge;

      // If any preferences changed, force new profile fetch
      if (preferencesChanged) {
        navigate('/swipe');
      } else {
        // Only preserve state if no preferences changed
        navigate('/swipe', {
          state: {
            profiles: location.state?.profiles,
            currentIndex: location.state?.currentIndex
          }
        });
      }
    } catch (err) {
      setError(err.message || 'Failed to update preferences');
    } finally {
      setSaving(false);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleBack = () => {
    // Check if any preferences changed
    const preferencesChanged = 
      user?.interested_in !== interestedIn ||
      user?.min_age_preference !== minAge ||
      user?.max_age_preference !== maxAge;

    // If any preferences changed, force new profile fetch
    if (preferencesChanged) {
      navigate('/swipe');
    } else {
      // Only preserve state if no preferences changed
      navigate('/swipe', {
        state: {
          profiles: location.state?.profiles,
          currentIndex: location.state?.currentIndex
        }
      });
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-md mx-auto h-full p-4">
        <div className="flex items-center justify-between mb-6">
          <button
            onClick={handleBack}
            className="bg-white p-2 rounded-full shadow-sm hover:bg-gray-50 transition-colors"
          >
            <ChevronLeft size={24} className="text-gray-600" />
          </button>
          <h1 className="text-2xl font-bold">Settings</h1>
          <div className="w-8"></div> {/* Spacer for centering */}
        </div>

        <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
          {error && (
            <div className="bg-red-50 border border-red-200 text-red-600 p-3 rounded-lg">
              {error}
            </div>
          )}

          {/* Admin Dashboard Link */}
          {user?.isAdmin && (
            <div className="border-b border-gray-200 pb-6">
              <button
                onClick={() => navigate('/admin')}
                className="w-full flex items-center justify-center gap-2 bg-indigo-600 text-white font-semibold py-3 px-4 rounded-lg hover:bg-indigo-700 transition-colors"
              >
                <LayoutDashboard size={20} />
                Admin Dashboard
              </button>
            </div>
          )}

          <div className="space-y-6">
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                I'm interested in
              </label>
              <div className="grid grid-cols-2 gap-4">
                <button
                  type="button"
                  onClick={() => setInterestedIn('female')}
                  className={`px-4 py-2 rounded-lg border transition-all ${
                    interestedIn === 'female'
                      ? 'bg-purple-100 border-purple-300 text-purple-700'
                      : 'bg-gray-50 border-gray-200 text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  Women
                </button>
                <button
                  type="button"
                  onClick={() => setInterestedIn('male')}
                  className={`px-4 py-2 rounded-lg border transition-all ${
                    interestedIn === 'male'
                      ? 'bg-purple-100 border-purple-300 text-purple-700'
                      : 'bg-gray-50 border-gray-200 text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  Men
                </button>
              </div>
            </div>

            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Age Range: {minAge} - {maxAge}
              </label>
              <div className="space-y-4">
                <div>
                  <label className="block text-gray-500 text-sm mb-1">Minimum Age</label>
                  <input
                    type="range"
                    min="18"
                    max="100"
                    value={minAge}
                    onChange={(e) => setMinAge(Math.min(parseInt(e.target.value), maxAge))}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-purple-600"
                  />
                </div>
                <div>
                  <label className="block text-gray-500 text-sm mb-1">Maximum Age</label>
                  <input
                    type="range"
                    min="18"
                    max="100"
                    value={maxAge}
                    onChange={(e) => setMaxAge(Math.max(parseInt(e.target.value), minAge))}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-purple-600"
                  />
                </div>
              </div>
            </div>
          </div>

          <button
            onClick={updatePreferences}
            disabled={saving}
            className={`w-full bg-purple-600 text-white font-semibold py-3 px-4 rounded-lg hover:bg-purple-700 transition-colors ${
              saving ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {saving ? 'Saving...' : 'Save Changes'}
          </button>

          <div className="border-t border-gray-200 pt-6">
            <button
              onClick={handleLogout}
              className="w-full flex items-center justify-center gap-2 text-red-600 font-medium hover:text-red-700 transition-colors"
            >
              <LogOut size={20} />
              Log Out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsScreen;
