class ProfileService {
  constructor(authenticatedFetch) {
    this.authenticatedFetch = authenticatedFetch;
  }

  /**
   * Get next available profile
   * @param {string} gender - Gender of profile to get
   */
  async getNextProfile(gender) {
    try {
      const response = await this.authenticatedFetch(`/api/profiles/next?gender=${gender}`);
      if (!response.ok) {
        throw new Error('Failed to get next profile');
      }
      const data = await response.json();
      
      // Return the profile from the response
      if (!data.success || !data.profile) {
        throw new Error('Invalid profile response');
      }
      
      return {
        ...data.profile,
        // Add any additional fields needed by the chat interface
        type: 'ai',
        messageDelay: Math.floor(Math.random() * (4000 - 2000 + 1)) + 2000, // Random delay between 2-4 seconds
      };
    } catch (error) {
      console.error('Error getting next profile:', error);
      throw error;
    }
  }

  /**
   * Generate a batch of profiles
   * @param {string} gender - Gender of profiles to generate
   * @param {number} count - Number of profiles to generate
   */
  async generateProfiles(gender, count = 10) {
    try {
      const response = await this.authenticatedFetch(
        `/api/profiles/batch?gender=${gender}&count=${count}`
      );
      if (!response.ok) {
        throw new Error('Failed to generate profiles');
      }
      const data = await response.json();
      
      if (!data.success || !Array.isArray(data.profiles)) {
        throw new Error('Invalid profiles response');
      }
      
      return data.profiles.map(profile => ({
        ...profile,
        type: 'ai',
        messageDelay: Math.floor(Math.random() * (4000 - 2000 + 1)) + 2000,
      }));
    } catch (error) {
      console.error('Error generating profiles:', error);
      throw error;
    }
  }
}

export default ProfileService;
