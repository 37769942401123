import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Heart, X, Info, Settings, Star } from 'lucide-react';
import MatchModal from './MatchModal';

const LoadingSpinner = () => (
  <div className="h-[70vh] flex flex-col items-center justify-center bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 rounded-2xl">
    <div className="relative w-32 h-32">
      {/* Spinner rings */}
      <motion.div
        className="absolute inset-0 border-4 border-white/30 rounded-full"
        animate={{
          rotate: 360,
          scale: [1, 1.1, 1],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
      <motion.div
        className="absolute inset-2 border-4 border-white/50 rounded-full"
        animate={{
          rotate: -360,
          scale: [1, 0.9, 1],
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
      <motion.div
        className="absolute inset-4 border-4 border-white rounded-full"
        animate={{
          rotate: 360,
          scale: [1, 1.1, 1],
        }}
        transition={{
          duration: 1.5,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
    </div>
    <motion.p 
      className="mt-8 text-white text-xl font-medium"
      animate={{
        opacity: [0.5, 1, 0.5],
      }}
      transition={{
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut"
      }}
    >
      Finding matches...
    </motion.p>
  </div>
);

const SwipeScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, authenticatedFetch } = useAuth();
  const [profiles, setProfiles] = useState(location.state?.profiles || []);
  const [currentIndex, setCurrentIndex] = useState(location.state?.currentIndex || 0);
  const [loading, setLoading] = useState(!location.state?.profiles);
  const [showInfo, setShowInfo] = useState(false);
  const [direction, setDirection] = useState(null);
  const [showMatch, setShowMatch] = useState(false);
  const [currentMatch, setCurrentMatch] = useState(null);
  const [fetchingMore, setFetchingMore] = useState(false);
  const isFetchingRef = useRef(false);
  const lastPreferences = useRef({ min: user?.min_age_preference, max: user?.max_age_preference });

  const debugLog = (message, data = {}) => {
    console.group(`🎭 SwipeScreen: ${message}`);
    console.log('State:', {
      profilesCount: profiles.length,
      currentIndex,
      loading,
      fetchingMore,
      isFetching: isFetchingRef.current,
      userInterestedIn: user?.interested_in,
      agePrefs: `${user?.min_age_preference}-${user?.max_age_preference}`
    });
    if (Object.keys(data).length > 0) {
      console.log('Data:', data);
    }
    console.groupEnd();
  };

  // Check if profiles match current age preferences
  const checkProfilesMatchPreferences = (profiles, minAge, maxAge) => {
    return profiles.every(profile => 
      profile.age >= minAge && profile.age <= maxAge
    );
  };

  // Effect to handle age preference changes
  useEffect(() => {
    if (user?.min_age_preference && user?.max_age_preference) {
      const preferencesChanged = 
        lastPreferences.current.min !== user.min_age_preference ||
        lastPreferences.current.max !== user.max_age_preference;

      if (preferencesChanged && profiles.length > 0) {
        const profilesMatch = checkProfilesMatchPreferences(
          profiles,
          user.min_age_preference,
          user.max_age_preference
        );

        if (!profilesMatch) {
          debugLog('Profiles do not match new age preferences, resetting...', {
            currentProfiles: profiles.map(p => p.age),
            newPrefs: `${user.min_age_preference}-${user.max_age_preference}`
          });
          // Clear current profiles and set loading to trigger new fetch
          setProfiles([]);
          setCurrentIndex(0);
          setLoading(true);
        }
      }

      // Update last preferences
      lastPreferences.current = {
        min: user.min_age_preference,
        max: user.max_age_preference
      };
    }
  }, [user?.min_age_preference, user?.max_age_preference]);

  useEffect(() => {
    if (!location.state?.profiles) {
      debugLog('No profiles in state, fetching...');
      fetchProfiles();
    } else {
      debugLog('Using profiles from state', { 
        profiles: location.state.profiles,
        currentIndex: location.state.currentIndex
      });
    }
  }, []);

  const fetchProfiles = async () => {
    if (isFetchingRef.current) {
      debugLog('Already fetching profiles, skipping');
      return;
    }
    
    try {
      debugLog('Fetching profiles...', {
        ageRange: `${user.min_age_preference}-${user.max_age_preference}`
      });
      isFetchingRef.current = true;
      setFetchingMore(true);
      
      // Try to get next available profile
      const response = await authenticatedFetch(
        `/api/profiles/next?gender=${user.interested_in}`
      );
      const data = await response.json();
      
      debugLog('Fetch response:', data);

      if (data.success && data.profile) {
        // Single profile response
        debugLog('Adding profile');
        setProfiles(prev => [...prev, data.profile]);
      } else {
        console.error('No more existing profiles available');
      }
    } catch (error) {
      console.error('Error fetching profiles:', error);
    } finally {
      setLoading(false);
      setFetchingMore(false);
      isFetchingRef.current = false;
    }
  };

  // Fetch more profiles when we're getting low
  useEffect(() => {
    if (profiles.length - currentIndex <= 2 && !isFetchingRef.current) {
      debugLog('Running low on profiles, fetching more...', {
        profilesLeft: profiles.length - currentIndex
      });
      fetchProfiles();
    }
  }, [currentIndex, profiles.length]);

  const handleDragEnd = (event, info) => {
    const swipeThreshold = 100;
    const swipeDirection = info.offset.x;
    const velocity = info.velocity.x;

    if (Math.abs(swipeDirection) > swipeThreshold || Math.abs(velocity) > 800) {
      if (swipeDirection > 0) {
        setDirection('right');
        handleLike();
      } else {
        setDirection('left');
        handlePass();
      }
    }
  };

  const updateInteraction = async (profileId, status) => {
    try {
      debugLog('Updating interaction', { profileId, status });
      await authenticatedFetch('/api/profiles/interaction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          profileId,
          status
        })
      });
    } catch (error) {
      console.error('Error updating interaction:', error);
    }
  };

  const handleSuperLike = async () => {
    const profile = profiles[currentIndex];
    debugLog('Handling super like', { profile });
    
    try {
      const response = await authenticatedFetch('/api/matches', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          profile,
          superLike: true // Force match with super like
        })
      });

      const data = await response.json();
      debugLog('Super like response:', data);
      
      await updateInteraction(profile.id, 'matched');
      setCurrentMatch({
        ...data.match,
        profileData: profile, // Include profile data for MatchModal
        superLike: true
      });
      setShowMatch(true);
      
      // Move to next profile after animation
      setTimeout(() => {
        setCurrentIndex(prev => prev + 1);
        setDirection(null);
      }, 200);
    } catch (error) {
      console.error('Error creating super like match:', error);
      handlePass();
    }
  };

  const handleLike = async () => {
    const profile = profiles[currentIndex];
    debugLog('Handling like', { profile });
    
    try {
      const response = await authenticatedFetch('/api/matches', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ profile })
      });

      const data = await response.json();
      debugLog('Match response:', data);
      
      if (data.matched) {
        await updateInteraction(profile.id, 'matched');
        setCurrentMatch({
          ...data.match,
          profileData: profile // Include profile data for MatchModal
        });
        setShowMatch(true);
      } else {
        await updateInteraction(profile.id, 'passed');
        handlePass();
      }
    } catch (error) {
      console.error('Error creating match:', error);
      handlePass();
    }
  };

  const handlePass = async () => {
    const profile = profiles[currentIndex];
    debugLog('Handling pass', { profile });
    await updateInteraction(profile.id, 'passed');
    setTimeout(() => {
      setCurrentIndex(prev => prev + 1);
      setDirection(null);
    }, 100);
  };

  const handleCloseMatch = () => {
    setShowMatch(false);
    setCurrentMatch(null);
    // Move to next profile if not already moved
    if (currentIndex === profiles.indexOf(currentMatch?.profile)) {
      setCurrentIndex(prev => prev + 1);
      setDirection(null);
    }
  };

  const goToMatches = () => {
    navigate('/matches', {
      state: {
        returnTo: '/swipe',
        profiles,
        currentIndex
      }
    });
  };

  const goToSettings = () => {
    navigate('/settings', {
      state: {
        returnTo: '/swipe',
        profiles,
        currentIndex
      }
    });
  };

  const renderMainContent = () => {
    if (loading || (currentIndex >= profiles.length && fetchingMore)) {
      debugLog('Showing loading spinner', {
        loading,
        currentIndex,
        profilesLength: profiles.length,
        fetchingMore
      });
      return <LoadingSpinner />;
    }

    const currentProfile = profiles[currentIndex];
    if (!currentProfile) {
      debugLog('No current profile');
      return null;
    }

    // Get the first photo URL from the array
    const photoUrl = Array.isArray(currentProfile.photos) ? currentProfile.photos[0] : currentProfile.photos;
    debugLog('Rendering profile', { currentProfile, photoUrl });

    return (
      <AnimatePresence mode="wait">
        <motion.div
          key={currentIndex}
          className="relative h-[70vh] w-full bg-white rounded-2xl shadow-lg overflow-hidden"
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={handleDragEnd}
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ 
            scale: direction === 'up' ? 1.1 : 1,
            opacity: 1,
            x: direction === 'left' ? -1000 : direction === 'right' ? 1000 : 0,
            y: direction === 'up' ? -20 : 0, // Reduced upward movement
            rotate: direction === 'left' ? -20 : direction === 'right' ? 20 : direction === 'up' ? 0 : 0
          }}
          exit={{ 
            scale: direction === 'up' ? 1.1 : 0.95,
            opacity: 0,
            x: direction === 'left' ? -1000 : direction === 'right' ? 1000 : 0,
            y: direction === 'up' ? -20 : 0, // Reduced upward movement
            rotate: direction === 'left' ? -20 : direction === 'right' ? 20 : direction === 'up' ? 0 : 0,
            transition: { duration: 0.2 }
          }}
          transition={{ 
            type: 'spring',
            stiffness: 500,
            damping: 50
          }}
          whileDrag={{
            scale: 1.05,
            cursor: 'grabbing'
          }}
        >
          {photoUrl ? (
            <img
              src={photoUrl.url}
              alt={currentProfile.name}
              className="w-full h-full object-cover"
              onError={(e) => {
                console.error('Image failed to load:', photoUrl);
                e.target.src = 'https://via.placeholder.com/400x600?text=Image+Failed+to+Load';
              }}
              draggable="false"
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center bg-gray-200">
              <p className="text-gray-500">No image available</p>
            </div>
          )}

          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent p-6">
            <h2 className="text-white text-3xl font-bold mb-2">
              {currentProfile.name}, {currentProfile.age}
            </h2>
            <p className="text-white/90 text-lg mb-1">
              {currentProfile.occupation}
            </p>
            <p className="text-white/80">
              {currentProfile.location}
            </p>
          </div>

          <button
            onClick={() => setShowInfo(!showInfo)}
            className="absolute top-4 right-4 bg-white/20 backdrop-blur-sm p-2 rounded-full"
          >
            <Info className="text-white" size={24} />
          </button>

          {showInfo && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="absolute inset-0 bg-black/80 backdrop-blur-sm p-6 flex flex-col justify-center"
              onClick={() => setShowInfo(false)}
            >
              <h3 className="text-white text-xl font-semibold mb-4">About {currentProfile.name}</h3>
              <p className="text-white/90 mb-4">{currentProfile.bio}</p>
              <div className="flex flex-wrap gap-2">
                {currentProfile.interests.map((interest, index) => (
                  <span
                    key={index}
                    className="bg-white/20 text-white px-3 py-1 rounded-full text-sm"
                  >
                    {interest}
                  </span>
                ))}
              </div>
            </motion.div>
          )}
        </motion.div>
      </AnimatePresence>
    );
  };

  return (
    <div className="h-screen bg-gray-100">
      <div className="max-w-md mx-auto h-full p-4">
        {/* Header with settings and matches buttons */}
        <div className="flex justify-between mb-4">
          <button
            onClick={goToSettings}
            className="bg-white p-3 rounded-full shadow-sm hover:bg-gray-50 transition-colors"
          >
            <Settings size={20} className="text-gray-600" />
          </button>
          <button
            onClick={goToMatches}
            className="bg-white p-3 rounded-full shadow-sm hover:bg-gray-50 transition-colors"
          >
            <Heart size={20} className="text-gray-600" />
          </button>
        </div>

        {/* Main content area */}
        {renderMainContent()}

        {/* Action buttons */}
        {!loading && currentIndex < profiles.length && (
          <div className="flex justify-center gap-6 mt-6">
            <button
              onClick={() => {
                setDirection('left');
                handlePass();
              }}
              className="bg-white w-16 h-16 rounded-full flex items-center justify-center shadow-lg hover:scale-105 transition-transform"
            >
              <X className="text-red-500" size={32} />
            </button>

            <button
              onClick={() => {
                setDirection('up');
                handleSuperLike();
              }}
              className="bg-white w-16 h-16 rounded-full flex items-center justify-center shadow-lg hover:scale-105 transition-transform"
            >
              <Star className="text-blue-500" size={32} fill="currentColor" />
            </button>

            <button
              onClick={() => {
                setDirection('right');
                handleLike();
              }}
              className="bg-white w-16 h-16 rounded-full flex items-center justify-center shadow-lg hover:scale-105 transition-transform"
            >
              <Heart className="text-purple-500" size={32} />
            </button>
          </div>
        )}
      </div>

      <MatchModal 
        isOpen={showMatch} 
        onClose={handleCloseMatch} 
        match={currentMatch}
      />
    </div>
  );
};

export default SwipeScreen;
