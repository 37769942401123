// AppProvider.jsx
import React, { createContext, useContext, useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChatService } from '../services/ChatService';
import { ResponseAnalyzer } from '../services/ResponseAnalyzer';
import AIService from '../services/ai';
import GameService from '../services/GameService';
import { useAuth } from './AuthContext';

const AppContext = createContext();

// Global service instances
let globalServices = null;

const initializeGlobalServices = (token) => {
  if (globalServices) {
    console.log('🌍 AppContext: Using existing service instances');
    if (token) {
      globalServices.aiService.setToken(token);
    }
    return globalServices;
  }

  console.log('🌍 AppContext: Creating new service instances');
  const aiService = new AIService();
  if (token) {
    aiService.setToken(token);
  }

  globalServices = {
    aiService,
    chatService: new ChatService(aiService),
    responseAnalyzer: new ResponseAnalyzer(aiService),
    gameService: new GameService(aiService)
  };
  console.log('🌍 AppContext: Service instances created successfully');
  return globalServices;
};

export const AppProvider = ({ children }) => {
  const navigate = useNavigate();
  const { token } = useAuth();
  
  // Base states
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageCount, setMessageCount] = useState(0);
  const [rizzScore, setRizzScore] = useState(null);
  const [currentAnalysis, setCurrentAnalysis] = useState(null);

  // Game states
  const [chatMode, setChatMode] = useState('text');
  const [gameMode, setGameMode] = useState('classic');
  const [lifeBar, setLifeBar] = useState(100);
  const [timeLeft, setTimeLeft] = useState(180);
  const [isGameActive, setIsGameActive] = useState(false);
  const [isCalculatingScore, setIsCalculatingScore] = useState(false);

  // Refs
  const gameEndInProgress = useRef(false);
  const navigationScheduled = useRef(false);
  const services = useRef(null);
  const currentMatchId = useRef(null);

  // Initialize services and update token when it changes
  useEffect(() => {
    if (!services.current) {
      console.log('🌍 AppContext: Initializing services');
      services.current = initializeGlobalServices(token);
      console.log('🌍 AppContext: Services initialized');
    } else if (token) {
      console.log('🌍 AppContext: Updating token in services');
      services.current.aiService.setToken(token);
    }
  }, [token]);

  const debugLog = (message, data = {}) => {
    console.group(`🌍 AppContext: ${message}`);
    console.log('Current State:', {
      isGameActive,
      isCalculatingScore,
      rizzScore,
      messageCount,
      gameMode,
      lifeBar,
      gameEndInProgress: gameEndInProgress.current,
      navigationScheduled: navigationScheduled.current,
      hasCharacter: !!selectedCharacter,
      hasServices: !!services.current,
      serviceKeys: services.current ? Object.keys(services.current) : [],
      currentMatchId: currentMatchId.current,
      messagesCount: messages.length,
      hasToken: !!token
    });
    console.log('Updates:', data);
    console.groupEnd();
  };

  // Update game state function
  const updateGameState = useCallback(async (updates) => {
    debugLog('Updating game state', updates);
    
    try {
      const batchedUpdates = Object.entries(updates);
      const promises = batchedUpdates.map(([key, value]) => {
        return new Promise(resolve => {
          switch (key) {
            case 'rizzScore':
              setRizzScore(value);
              break;
            case 'currentAnalysis':
              setCurrentAnalysis(value);
              break;
            case 'isGameActive':
              setIsGameActive(value);
              break;
            case 'messages':
              setMessages(value);
              break;
            case 'messageCount':
              setMessageCount(value);
              break;
            case 'lifeBar':
              setLifeBar(value);
              break;
            case 'timeLeft':
              setTimeLeft(value);
              break;
            case 'isCalculatingScore':
              setIsCalculatingScore(value);
              break;
            default:
              break;
          }
          resolve();
        });
      });

      await Promise.all(promises);
      debugLog('State update complete', updates);
    } catch (error) {
      console.error('Error updating game state:', error);
      throw error;
    }
  }, []);

  const analyzeAndUpdateLifeBar = useCallback(async (aiMessage, userResponse) => {
    if (!services.current || gameMode !== 'lifeBar' || !isGameActive || gameEndInProgress.current) return;
    
    try {
      const responseQuality = await services.current.responseAnalyzer.analyzeResponse(
        aiMessage,
        userResponse
      );

      setLifeBar((prev) => {
        const newValue = responseQuality === 'good' ? Math.min(100, prev + 4) :
                        responseQuality === 'bad' ? Math.max(0, prev - 8) :
                        prev;
        return newValue;
      });
    } catch (error) {
      console.error('Response analysis error:', error);
    }
  }, [gameMode, isGameActive]);

  const handleGameEnd = useCallback(async () => {
    if (!services.current || gameEndInProgress.current || navigationScheduled.current) {
      debugLog('Game end or navigation already in progress, skipping');
      return;
    }

    debugLog('Starting game end process');
    try {
      gameEndInProgress.current = true;
      navigationScheduled.current = true;

      // First update game state
      await updateGameState({
        isGameActive: false,
        isCalculatingScore: true
      });

      // Calculate final score
      const finalAnalysis = await services.current.chatService.calculateRizzScore(messages);
      debugLog('Score calculated', { finalAnalysis });

      // Update final states
      await updateGameState({
        isCalculatingScore: false,
        currentAnalysis: finalAnalysis,
        rizzScore: finalAnalysis.score
      });

      // Schedule navigation for next tick to ensure state updates are processed
      setTimeout(() => {
        debugLog('Navigating to score screen');
        navigate('/score', { replace: true });
        navigationScheduled.current = false;
      }, 0);

    } catch (error) {
      console.error('Game end error:', error);
      await updateGameState({
        isCalculatingScore: false,
        isGameActive: false
      });
      navigationScheduled.current = false;
    } finally {
      gameEndInProgress.current = false;
    }
  }, [messages, updateGameState, navigate]);

  // Reset game function
  const resetGame = useCallback(() => {
    debugLog('Resetting game state');
    gameEndInProgress.current = false;
    navigationScheduled.current = false;
    updateGameState({
      messages: [],
      messageCount: 0,
      rizzScore: null,
      currentAnalysis: null,
      lifeBar: 100,
      timeLeft: 180,
      isGameActive: true,
      isCalculatingScore: false,
    });
    setSelectedCharacter(null);
  }, [updateGameState]);

  // Game end conditions effect
  useEffect(() => {
    const shouldEndGame = (
      (gameMode === 'lifeBar' && lifeBar <= 0.1 && isGameActive && !isCalculatingScore) ||
      (gameMode === 'classic' && !isGameActive && messages.length > 0 && !isCalculatingScore) ||
      (gameMode === 'timeTrial' && timeLeft <= 0 && isGameActive && !isCalculatingScore)
    );

    if (shouldEndGame && !gameEndInProgress.current && !navigationScheduled.current) {
      debugLog('Game end condition met', { 
        gameMode, 
        lifeBar, 
        isGameActive, 
        messageCount: messages.length,
        isCalculatingScore
      });
      handleGameEnd();
    }
  }, [gameMode, lifeBar, isGameActive, messages.length, timeLeft, isCalculatingScore, handleGameEnd]);

  // Memoize context value
  const value = useMemo(
    () => ({
      // Base states
      selectedCharacter,
      setSelectedCharacter,
      messages,
      setMessages,
      messageCount,
      setMessageCount,
      rizzScore,
      setRizzScore,
      currentAnalysis,
      setCurrentAnalysis,

      // Game states
      chatMode,
      setChatMode,
      gameMode,
      setGameMode,
      lifeBar,
      setLifeBar,
      timeLeft,
      setTimeLeft,
      isGameActive,
      setIsGameActive,
      isCalculatingScore,
      setIsCalculatingScore,

      // Methods
      updateGameState,
      resetGame,
      analyzeAndUpdateLifeBar,
      handleGameEnd,

      // Services
      aiService: services.current?.aiService,
      gameService: services.current?.gameService,

      // Match tracking
      currentMatchId: currentMatchId.current,
      setCurrentMatchId: (id) => {
        if (id !== currentMatchId.current) {
          debugLog('Switching match', { from: currentMatchId.current, to: id });
          currentMatchId.current = id;
          setMessages([]); // Clear messages when switching matches
        }
      }
    }),
    [
      selectedCharacter,
      messages,
      messageCount,
      rizzScore,
      currentAnalysis,
      chatMode,
      gameMode,
      lifeBar,
      timeLeft,
      isGameActive,
      isCalculatingScore,
      updateGameState,
      resetGame,
      analyzeAndUpdateLifeBar,
      handleGameEnd,
      services.current
    ]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
};

export default AppProvider;
