import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(localStorage.getItem('token'));

  // Get the API URL from environment variable
  const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:5023';

  const register = async (email, password, name, interested_in) => {
    console.log('Starting registration process for:', email);
    try {
      const response = await fetch(`${API_URL}/api/auth/register`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ email, password, name, interested_in }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Registration failed');
      }
      
      const data = await response.json();
      console.log('Registration successful:', { email, name, interested_in });
      return data;
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  };

  const login = async (email, password) => {
    try {
      const response = await fetch(`${API_URL}/api/auth/login`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      
      if (response.ok) {
        localStorage.setItem('token', data.token);
        setToken(data.token);
        setUser(data.user);
        return { success: true };
      } else {
        console.error('Login failed:', data.message);
        return { success: false, error: data.message };
      }
    } catch (error) {
      console.error('Login error:', error);
      return { success: false, error: 'Login failed' };
    }
  };

  const refreshToken = async () => {
    try {
      const response = await fetch(`${API_URL}/api/auth/refresh-token`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        setToken(data.token);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const authenticatedFetch = async (url, options = {}) => {
    try {
      const headers = {
        ...options.headers,
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      };

      let response = await fetch(url.startsWith('/') ? `${API_URL}${url}` : url, { 
        ...options, 
        headers 
      });

      if (response.status === 401) {
        const refreshed = await refreshToken();
        if (refreshed) {
          headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
          response = await fetch(url.startsWith('/') ? `${API_URL}${url}` : url, { 
            ...options, 
            headers 
          });
        } else {
          logout();
          throw new Error('Session expired');
        }
      }

      return response;
    } catch (error) {
      throw error;
    }
  };

  const updateUser = (userData) => {
    setUser(prevUser => ({
      ...prevUser,
      ...userData
    }));
  };

  const logout = () => {
    localStorage.removeItem('token');
    setToken(null);
    setUser(null);
  };

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const currentToken = localStorage.getItem('token');

        if (currentToken) {
          const response = await fetch(`${API_URL}/api/auth/verify-token`, {
            headers: { 'Authorization': `Bearer ${currentToken}` }
          });
          
          if (response.ok) {
            const data = await response.json();
            setUser(data.user);
            setToken(currentToken);
          } else {
            localStorage.removeItem('token');
            setToken(null);
          }
        }
      } catch (error) {
        console.error('Token verification failed:', error);
      } finally {
        setLoading(false);
      }
    };

    verifyToken();
  }, []);

  useEffect(() => {
    const currentToken = localStorage.getItem('token');
    if (currentToken !== token) {
      setToken(currentToken);
    }
  }, [token]);

  const value = {
    user,
    loading,
    login,
    register,
    logout,
    token,
    authenticatedFetch,
    updateUser
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext };
export default AuthProvider;
