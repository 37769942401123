import { useState, useEffect, useRef } from 'react';
import { useApp } from '../../../contexts/AppContext';
import { useAuth } from '../../../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import { baseSystemPrompt, generatePersonalityPrompt } from '../../../config/characters';
import ProfileService from '../../../services/ProfileService';

export const useInitialization = () => {
  const location = useLocation();
  const { 
    messages, 
    setMessages, 
    setIsGameActive, 
    aiService: appAIService,
    resetGame
  } = useApp();
  const { user, authenticatedFetch } = useAuth();
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState(null);
  const [serviceStatus, setServiceStatus] = useState('checking');
  const [personality, setPersonality] = useState(location.state?.character || null);
  const [isGenerating, setIsGenerating] = useState(false);
  const messagesEndRef = useRef(null);
  const messageIdRef = useRef(1);
  const isGeneratingRef = useRef(false);
  const initAttempts = useRef(0);
  const hasInitialized = useRef(false);
  const currentMatchId = useRef(location.state?.matchId);
  const isLoadingMessages = useRef(false);
  const profileService = useRef(null);

  // Use environment variable from Vercel/Vite
  const API_URL = process.env.VITE_API_URL || 'http://localhost:5023';

  const debugLog = (message, data = {}) => {
    console.group(`🎭 Personality System: ${message}`);
    console.log('State:', {
      hasPersonality: !!personality,
      serviceStatus,
      messagesCount: messages.length,
      isTyping,
      hasError: !!error,
      isGenerating: isGeneratingRef.current,
      initAttempts: initAttempts.current,
      hasAIService: !!appAIService,
      userInterestedIn: user?.interested_in,
      userName: user?.name,
      mode: location.state?.mode,
      hasInitialized: hasInitialized.current,
      matchId: location.state?.matchId,
      currentMatchId: currentMatchId.current,
      isLoadingMessages: isLoadingMessages.current,
      userAgePrefs: user ? `${user.min_age_preference}-${user.max_age_preference}` : 'default'
    });
    if (Object.keys(data).length > 0) {
      console.log('Data:', data);
    }
    console.groupEnd();
  };

  // Initialize profile service
  useEffect(() => {
    if (!profileService.current && authenticatedFetch) {
      profileService.current = new ProfileService(authenticatedFetch);
    }
  }, [authenticatedFetch]);

  // Reset state when component unmounts
  useEffect(() => {
    return () => {
      hasInitialized.current = false;
      currentMatchId.current = null;
      isLoadingMessages.current = false;
      setMessages([]);
      setPersonality(null);
      setIsGenerating(false);
    };
  }, [setMessages]);

  // Handle chat switching and initialization
  useEffect(() => {
    const initializeChat = async () => {
      // If we're switching chats or haven't initialized yet
      if (location.state?.matchId !== currentMatchId.current || !hasInitialized.current) {
        debugLog('Initializing chat', {
          from: currentMatchId.current,
          to: location.state?.matchId,
          hasInitialized: hasInitialized.current
        });

        // Reset state when switching chats
        if (currentMatchId.current !== location.state?.matchId) {
          resetGame();
          setMessages([]);
          // Only reset personality if we're not going to a match chat
          if (!location.state?.matchId) {
            setPersonality(null);
          }
        }

        // Update current match ID
        currentMatchId.current = location.state?.matchId;
        hasInitialized.current = false;

        // Load messages for match chat
        if (location.state?.matchId && !isLoadingMessages.current) {
          isLoadingMessages.current = true;
          try {
            const response = await authenticatedFetch(`/api/matches/${location.state.matchId}/messages`);
            const data = await response.json();
            
            if (data.messages && data.messages.length > 0) {
              const formattedMessages = data.messages.map(msg => ({
                id: msg.id,
                text: msg.content,
                sender: msg.senderId === null ? 'ai' : 'user',
                character: location.state.character.name
              }));
              setMessages(formattedMessages);
              messageIdRef.current = Math.max(...data.messages.map(m => m.id)) + 1;
            } else {
              setMessages([]);
            }
            
            // Set personality for match chat
            if (location.state.character) {
              setPersonality(location.state.character);
            }

            hasInitialized.current = true;
            setIsGameActive(true);
          } catch (error) {
            console.error('Error loading match messages:', error);
            setError('Failed to load chat history. Please try again.');
          } finally {
            isLoadingMessages.current = false;
          }
        }
        // Handle swipe mode initialization
        else if (location.state?.mode === 'swipe' && location.state?.character) {
          debugLog('Using swipe mode character', { character: location.state.character });
          setPersonality(location.state.character);
          setIsGameActive(true);
          hasInitialized.current = true;
        }
        // Handle regular chat initialization
        else if (!hasInitialized.current && !isGeneratingRef.current && user?.interested_in && serviceStatus === 'ready') {
          debugLog('Starting regular chat initialization');
          
          try {
            isGeneratingRef.current = true;
            setIsGenerating(true);
            setPersonality(null); // Clear personality while generating new one

            // Get next profile from profile service
            const profile = await profileService.current.getNextProfile(user.interested_in);
            debugLog('Got next profile', { profile });

            setPersonality(profile);
            setIsGameActive(true);
            hasInitialized.current = true;
            debugLog('Chat initialized successfully');
          } catch (error) {
            console.error('Error initializing chat:', error);
            debugLog('Chat initialization failed', { 
              error: error.message,
              stack: error.stack,
              attempt: initAttempts.current
            });
            setError('Failed to start chat. Please refresh the page.');
          } finally {
            isGeneratingRef.current = false;
            setIsGenerating(false);
          }
        }
      }
    };

    initializeChat();
  }, [
    location.state?.matchId,
    location.state?.mode,
    location.state?.character,
    serviceStatus,
    user?.interested_in,
    appAIService,
    setMessages,
    setIsGameActive,
    authenticatedFetch,
    resetGame,
    user
  ]);

  // Initialize services and check health
  useEffect(() => {
    const initializeServices = async () => {
      if (!appAIService) {
        debugLog('AIService not initialized yet, skipping health check');
        return;
      }

      try {
        debugLog('Starting service health check');
        const response = await fetch(`${API_URL}/health`);
        const isHealthy = response.ok;
        setServiceStatus(isHealthy ? 'ready' : 'error');
        debugLog('Service health check complete', { isHealthy });
      } catch (error) {
        console.error('Health check failed:', error);
        debugLog('Health check failed', { 
          error: error.message,
          stack: error.stack 
        });
        setServiceStatus('error');
        setError('Unable to connect to AI service. Please try again later.');
      }
    };

    initializeServices();
  }, [API_URL, appAIService]);

  // Auto-scroll effect
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return {
    serviceStatus,
    error,
    setError,
    messages,
    isTyping,
    setIsTyping,
    messagesEndRef,
    messageIdRef,
    currentPersonality: personality,
    isGenerating,
  };
};

export default useInitialization;
