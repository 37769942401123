// GameService.js
import { ChatService } from './ChatService';
import { ResponseAnalyzer } from './ResponseAnalyzer';

export class GameService {
  constructor(aiService) {
    if (!aiService) {
      throw new Error('AIService is required for GameService');
    }
    console.log('🎮 GameService: Initializing with AIService');
    this.chatService = new ChatService(aiService);
    this.responseAnalyzer = new ResponseAnalyzer(aiService);
  }

  async calculateScore(messages) {
    try {
      // Get the final score and analysis from chat service
      const analysis = await this.chatService.calculateRizzScore(messages);
      
      return {
        scores: analysis.scores,
        analysis: analysis.analysis,
        score: analysis.analysis.overallCharm
      };
    } catch (error) {
      console.error('Error calculating score:', error);
      throw error;
    }
  }

  async submitGame(gameData, authenticatedFetch) {
    try {
      // Ensure strengthScores is properly formatted
      const formattedGameData = {
        ...gameData,
        strengthScores: gameData.strengthScores ? {
          conversationFlow: gameData.strengthScores.conversationFlow.score,
          emotionalConnection: gameData.strengthScores.emotionalConnection.score,
          flirtingSkills: gameData.strengthScores.flirtingSkills.score,
          confidence: gameData.strengthScores.confidence.score,
          humor: gameData.strengthScores.humor.score
        } : {
          // Default scores if none provided
          conversationFlow: 1,
          emotionalConnection: 1,
          flirtingSkills: 1,
          confidence: 1,
          humor: 1
        }
      };
      
      console.log('🎮 GameService: Submitting game data:', formattedGameData);
      
      const response = await authenticatedFetch('/api/game/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formattedGameData)
      });

      if (!response.ok) {
        const error = await response.json();
        console.error('🎮 GameService: Game submission failed:', error);
        throw new Error(error.message || 'Failed to submit game');
      }

      const result = await response.json();
      console.log('🎮 GameService: Game submission successful:', result);

      // Verify we have the required fields
      if (!result.newRating || result.ratingChange === undefined) {
        throw new Error('Invalid response from server: missing rating data');
      }

      return {
        newRating: result.newRating,
        ratingChange: result.ratingChange,
        ratingTier: result.ratingTier,
        stats: result.stats
      };
    } catch (error) {
      console.error('🎮 GameService: Error submitting game:', error);
      throw error;
    }
  }

  async getUserStats(authenticatedFetch) {
    try {
      const response = await authenticatedFetch('/api/game/stats');
      
      if (!response.ok) {
        const error = await response.json();
        console.error('🎮 GameService: Failed to fetch user stats:', error);
        throw new Error(error.message || 'Failed to fetch user stats');
      }

      const data = await response.json();
      console.log('🎮 GameService: Fetched user stats:', data);
      return data;
    } catch (error) {
      console.error('🎮 GameService: Error fetching user stats:', error);
      throw error;
    }
  }

  async getGameHistory(authenticatedFetch) {
    try {
      const response = await authenticatedFetch('/api/game/history');
      
      if (!response.ok) {
        const error = await response.json();
        console.error('🎮 GameService: Failed to fetch game history:', error);
        throw new Error(error.message || 'Failed to fetch game history');
      }

      const data = await response.json();
      console.log('🎮 GameService: Fetched game history:', data);
      return data.games;
    } catch (error) {
      console.error('🎮 GameService: Error fetching game history:', error);
      throw error;
    }
  }

  async getLeaderboard(authenticatedFetch, category = 'overall') {
    try {
      const response = await authenticatedFetch(`/api/leaderboard?category=${category}`);
      
      if (!response.ok) {
        const error = await response.json();
        console.error('🎮 GameService: Failed to fetch leaderboard:', error);
        throw new Error(error.message || 'Failed to fetch leaderboard');
      }

      const data = await response.json();
      console.log('🎮 GameService: Fetched leaderboard:', data);
      return data;
    } catch (error) {
      console.error('🎮 GameService: Error fetching leaderboard:', error);
      throw error;
    }
  }
}

export default GameService;
