// ChatHeader.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChevronLeft, User } from 'lucide-react';

const ChatHeader = ({ type }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMatchChat = location.state?.mode === 'swipe';

  const handleBack = () => {
    if (isMatchChat) {
      navigate('/matches');
    } else {
      navigate('/');
    }
  };

  const handleProfile = () => {
    // Navigate back to matches screen with profile view open
    navigate('/matches', { 
      state: { 
        showProfile: true,
        matchId: location.state?.matchId
      }
    });
  };

  // Get the image URL from the character's photos
  const getImageUrl = () => {
    const character = location.state?.character;
    if (!character) return null;

    // Handle both array and single photo cases
    const photos = character.photos;
    if (!photos) return null;

    // If photos is an array, get first photo's URL
    if (Array.isArray(photos) && photos.length > 0) {
      return photos[0].url;
    }
    // If photos is a single object, get its URL
    if (photos.url) {
      return photos.url;
    }

    return null;
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-black bg-opacity-20 p-4 text-white flex items-center justify-between"
    >
      <div className="flex items-center space-x-3">
        <button
          onClick={handleBack}
          className="w-10 h-10 rounded-full bg-white bg-opacity-20 flex items-center justify-center hover:bg-opacity-30 transition-colors"
        >
          <ChevronLeft size={24} />
        </button>

        {isMatchChat ? (
          <>
            <div 
              className="w-10 h-10 rounded-full overflow-hidden cursor-pointer"
              onClick={handleProfile}
            >
              {getImageUrl() ? (
                <img
                  src={getImageUrl()}
                  alt={location.state?.character?.name}
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    console.error('Image failed to load:', getImageUrl());
                    e.target.src = 'https://via.placeholder.com/40?text=?';
                  }}
                />
              ) : (
                <div className="w-full h-full bg-gray-300 flex items-center justify-center">
                  <span className="text-gray-600">?</span>
                </div>
              )}
            </div>
            <div>
              <h2 className="text-lg font-semibold">{location.state?.character?.name}</h2>
              <p className="text-sm opacity-75">Online Now</p>
            </div>
          </>
        ) : (
          <>
            <div className="w-10 h-10 rounded-full bg-white bg-opacity-20 flex items-center justify-center">
              <span className="text-xl font-semibold">
                {type.charAt(0)}
              </span>
            </div>
            <div>
              <h2 className="text-lg font-semibold">{type}</h2>
              <p className="text-sm opacity-75">Online Now</p>
            </div>
          </>
        )}
      </div>

      {isMatchChat && (
        <button
          onClick={handleProfile}
          className="w-10 h-10 rounded-full bg-white bg-opacity-20 flex items-center justify-center hover:bg-opacity-30 transition-colors"
        >
          <User size={20} />
        </button>
      )}
    </motion.div>
  );
};

export default ChatHeader;
