// src/services/ai.js
export class AIService {
  constructor() {
    this.API_URL = import.meta.env.VITE_API_URL;
    this.token = null;

    console.log('🤖 AIService: Constructor called', {
      hasToken: false,
      apiUrl: this.API_URL,
      envVars: Object.keys(import.meta.env)
    });
  }

  setToken(token) {
    console.log('🤖 AIService: Setting token', {
      hadToken: !!this.token,
      hasNewToken: !!token,
      tokenLength: token?.length
    });
    this.token = token;
  }

  getHeaders() {
    if (!this.token) {
      console.error('🤖 AIService: No token available when getting headers');
      throw new Error('No authentication token available');
    }

    return {
      'Authorization': `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    };
  }

  async streamChat(messages, onToken) {
    try {
      console.log('🤖 AIService: Starting stream chat', { 
        messages,
        hasToken: !!this.token,
        tokenLength: this.token?.length
      });
      const response = await fetch(`${this.API_URL}/api/ai/chat/completions`, {
        method: 'POST',
        headers: this.getHeaders(),
        body: JSON.stringify({
          model: 'anthropic/claude-3.5-sonnet:beta',
          messages,
          stream: true,
          temperature: 0.7,
          max_tokens: 1000,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('🤖 AIService: Stream chat error', {
          status: response.status,
          error: errorText,
          headers: Object.fromEntries(response.headers.entries())
        });
        throw new Error(`API error: ${response.status} - ${errorText}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      while (true) {
        const { value, done } = await reader.read();
        
        if (done) break;
        
        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split('\n');
        
        // Keep last incomplete line in buffer
        buffer = lines.pop() || '';

        for (const line of lines) {
          if (!line.trim()) continue;
          
          if (line.includes('[DONE]')) {
            return;
          }

          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.slice(5));
              const content = data.choices?.[0]?.delta?.content;
              if (content) onToken(content);
            } catch (e) {
              console.warn('🤖 AIService: Error parsing stream data:', e, line);
            }
          }
        }
      }
    } catch (error) {
      console.error('🤖 AIService: Stream error:', {
        error: error.message,
        stack: error.stack,
        hasToken: !!this.token,
        tokenLength: this.token?.length
      });
      throw new Error(`Streaming failed: ${error.message}`);
    }
  }

  async chatCompletion(messages, model = 'anthropic/claude-3.5-sonnet:beta') {
    try {
      console.log('🤖 AIService: Starting chat completion', { 
        messages, 
        model,
        hasToken: !!this.token,
        tokenLength: this.token?.length
      });
      const response = await fetch(`${this.API_URL}/api/ai/chat/completions`, {
        method: 'POST',
        headers: this.getHeaders(),
        body: JSON.stringify({
          model,
          messages,
          temperature: 0.7,
          max_tokens: 1000
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('🤖 AIService: Chat completion error', {
          status: response.status,
          error: errorText,
          headers: Object.fromEntries(response.headers.entries())
        });
        throw new Error(`API error: ${response.status} - ${errorText}`);
      }

      const result = await response.json();
      console.log('🤖 AIService: Chat completion success', { result });
      return result;
    } catch (error) {
      console.error('🤖 AIService: Chat completion error:', {
        error: error.message,
        stack: error.stack,
        hasToken: !!this.token,
        tokenLength: this.token?.length
      });
      throw new Error(`Chat completion failed: ${error.message}`);
    }
  }

  async healthCheck() {
    try {
      console.log('🤖 AIService: Starting health check', {
        hasToken: !!this.token,
        tokenLength: this.token?.length
      });
      const response = await fetch(`${this.API_URL}/api/ai/health`, {
        headers: this.getHeaders()
      });

      if (!response.ok) {
        console.error('🤖 AIService: Health check failed', {
          status: response.status,
          headers: Object.fromEntries(response.headers.entries())
        });
        throw new Error(`Health check failed with status: ${response.status}`);
      }

      console.log('🤖 AIService: Health check success');
      return true;
    } catch (error) {
      console.error('🤖 AIService: Health check error:', {
        error: error.message,
        stack: error.stack,
        hasToken: !!this.token,
        tokenLength: this.token?.length
      });
      return false;
    }
  }
}

// Export the class instead of a singleton
export default AIService;
