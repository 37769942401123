// src/components/chat/ChatMessages.jsx
import React, { forwardRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Message = forwardRef(({ msg }, ref) => {
  console.log('Rendering message:', msg);
  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.2 }}
      className={`${msg.sender === 'ai' ? 'self-start' : 'self-end'} flex flex-col mb-4 max-w-[80%]`}
    >
      <div
        className={`${
          msg.sender === 'ai' ? 'bg-white/10' : 'bg-white/20'
        } backdrop-blur-sm rounded-xl p-4`}
      >
        <p className="text-white whitespace-pre-wrap break-words">{msg.text}</p>
      </div>
    </motion.div>
  );
});

const TypingIndicator = forwardRef((props, ref) => (
  <motion.div
    ref={ref}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
    className="bg-white/10 backdrop-blur-sm rounded-xl p-4 max-w-[80%] self-start"
  >
    <div className="flex space-x-2">
      <div className="w-2 h-2 bg-white/60 rounded-full animate-bounce" />
      <div className="w-2 h-2 bg-white/60 rounded-full animate-bounce delay-100" />
      <div className="w-2 h-2 bg-white/60 rounded-full animate-bounce delay-200" />
    </div>
  </motion.div>
));

const ChatMessages = React.memo(({ messages, isTyping, chatMode, messagesEndRef }) => {
  console.log('Rendering messages:', messages);
  return (
    <div className="flex-1 p-4 overflow-y-auto flex flex-col">
      <div className="flex-1 flex flex-col space-y-4">
        <AnimatePresence mode="popLayout">
          {messages.map((msg) => (
            <Message key={`${msg.id}-${msg.sender}`} msg={msg} />
          ))}
          {isTyping && chatMode === 'text' && <TypingIndicator key="typing" />}
        </AnimatePresence>
      </div>
      <div ref={messagesEndRef} />
    </div>
  );
});

Message.displayName = 'Message';
TypingIndicator.displayName = 'TypingIndicator';
ChatMessages.displayName = 'ChatMessages';

export default ChatMessages;
