import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Loader } from 'lucide-react';

const AdminDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState(null);
  const [period, setPeriod] = useState('daily');

  useEffect(() => {
    fetchStats();
  }, [period]);

  const fetchStats = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${import.meta.env.VITE_API_URL}/api/admin/token-usage?period=${period}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch stats');
      }

      const data = await response.json();
      setStats(data);
    } catch (err) {
      console.error('Error fetching stats:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader className="w-8 h-8 text-white animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 text-white">
        <div className="bg-red-500/20 border border-red-500/50 rounded p-4">
          <h2 className="text-xl font-bold mb-2">Error</h2>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen overflow-y-auto">
      <div className="p-6 text-white">
        <h1 className="text-3xl font-bold mb-8">Admin Dashboard</h1>

        {/* Period Selection */}
        <div className="mb-8">
          <div className="flex gap-4">
            {['daily', 'weekly', 'monthly'].map((p) => (
              <button
                key={p}
                onClick={() => setPeriod(p)}
                className={`px-4 py-2 rounded-lg transition ${
                  period === p
                    ? 'bg-white/20 text-white'
                    : 'bg-white/5 hover:bg-white/10 text-white/70'
                }`}
              >
                {p.charAt(0).toUpperCase() + p.slice(1)}
              </button>
            ))}
          </div>
        </div>

        {stats && (
          <>
            {/* Overview Cards */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
              <div className="bg-white/10 rounded-xl p-6">
                <h3 className="text-lg font-semibold mb-2">Total Tokens</h3>
                <p className="text-3xl font-bold">{stats.totals.totalTokens?.toLocaleString()}</p>
              </div>
              <div className="bg-white/10 rounded-xl p-6">
                <h3 className="text-lg font-semibold mb-2">Total Cost</h3>
                <p className="text-3xl font-bold">${Number(stats.totals.totalCost).toFixed(2)}</p>
              </div>
              <div className="bg-white/10 rounded-xl p-6">
                <h3 className="text-lg font-semibold mb-2">Message Count</h3>
                <p className="text-3xl font-bold">{stats.totals.messageCount?.toLocaleString()}</p>
              </div>
            </div>

            {/* Model Breakdown Chart */}
            <div className="bg-white/10 rounded-xl p-6 mb-8">
              <h2 className="text-xl font-bold mb-6">Model Usage Breakdown</h2>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={stats.modelBreakdown}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#ffffff20" />
                    <XAxis dataKey="modelName" stroke="#ffffff80" />
                    <YAxis stroke="#ffffff80" />
                    <Tooltip 
                      contentStyle={{ 
                        backgroundColor: '#ffffff20',
                        border: 'none',
                        borderRadius: '8px',
                        backdropFilter: 'blur(10px)'
                      }}
                    />
                    <Legend />
                    <Bar dataKey="totalTokens" name="Tokens Used" fill="#6366f1" />
                    <Bar dataKey="messageCount" name="Messages" fill="#8b5cf6" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            {/* User Stats Table */}
            <div className="bg-white/10 rounded-xl p-6 mb-8">
              <h2 className="text-xl font-bold mb-6">User Statistics</h2>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="border-b border-white/10">
                      <th className="text-left py-3 px-4">User</th>
                      <th className="text-right py-3 px-4">Tokens Used</th>
                      <th className="text-right py-3 px-4">Cost</th>
                      <th className="text-right py-3 px-4">Messages</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stats.userStats.map((stat) => (
                      <tr key={stat.userId} className="border-b border-white/5">
                        <td className="py-3 px-4">{stat.User.email}</td>
                        <td className="text-right py-3 px-4">{Number(stat.totalTokens).toLocaleString()}</td>
                        <td className="text-right py-3 px-4">${Number(stat.totalCost).toFixed(2)}</td>
                        <td className="text-right py-3 px-4">{Number(stat.messageCount).toLocaleString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
