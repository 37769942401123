import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageCircle, ChevronLeft, Info, X } from 'lucide-react';

const MatchesScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authenticatedFetch } = useAuth();
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [unmatchingId, setUnmatchingId] = useState(null);

  useEffect(() => {
    fetchMatches();
  }, []);

  const fetchMatches = async () => {
    try {
      const response = await authenticatedFetch('/api/matches');
      const data = await response.json();
      setMatches(data.matches);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching matches:', error);
      setLoading(false);
    }
  };

  const handleUnmatch = async (matchId) => {
    try {
      const response = await authenticatedFetch(`/api/matches/${matchId}/unmatch`, {
        method: 'POST'
      });
      
      if (response.ok) {
        // Remove match from state
        setMatches(matches.filter(match => match.id !== matchId));
      }
    } catch (error) {
      console.error('Error unmatching:', error);
    }
  };

  const getPhotoUrl = (photos) => {
    if (!photos) return null;

    // If photos is an array, get first photo's URL
    if (Array.isArray(photos) && photos.length > 0) {
      return photos[0].url;
    }
    // If photos is a single object, get its URL
    if (photos.url) {
      return photos.url;
    }

    return null;
  };

  const handleChatClick = (e, match) => {
    e.stopPropagation(); // Prevent drag from activating
    navigate('/chat', {
      state: {
        mode: 'swipe',
        matchId: match.id,
        character: {
          id: match.profileData.name.toLowerCase(),
          name: match.profileData.name,
          description: match.profileData.bio,
          photos: match.profileData.photos,
          personality: match.profileData.personality
        }
      }
    });
  };

  const handleProfileClick = (e, match) => {
    e.stopPropagation(); // Prevent drag from activating
    setSelectedProfile(match);
  };

  const handleBackClick = () => {
    // Return to SwipeScreen with preserved state
    if (location.state?.returnTo === '/swipe') {
      navigate('/swipe', {
        state: {
          profiles: location.state.profiles,
          currentIndex: location.state.currentIndex
        }
      });
    } else {
      navigate('/swipe');
    }
  };

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="h-screen bg-gray-100">
      {selectedProfile ? (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          className="h-full bg-white"
        >
          <div className="relative h-[60vh]">
            {getPhotoUrl(selectedProfile.profileData.photos) ? (
              <img
                src={getPhotoUrl(selectedProfile.profileData.photos)}
                alt={selectedProfile.profileData.name}
                className="w-full h-full object-cover"
                onError={(e) => {
                  console.error('Image failed to load:', getPhotoUrl(selectedProfile.profileData.photos));
                  e.target.src = 'https://via.placeholder.com/400x600?text=Image+Failed+to+Load';
                }}
              />
            ) : (
              <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                <p className="text-gray-500">No image available</p>
              </div>
            )}
            <button
              onClick={() => setSelectedProfile(null)}
              className="absolute top-4 left-4 bg-black/20 backdrop-blur-sm p-2 rounded-full text-white"
            >
              <ChevronLeft size={24} />
            </button>
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent p-6">
              <h2 className="text-white text-3xl font-bold mb-2">
                {selectedProfile.profileData.name}, {selectedProfile.profileData.age}
              </h2>
              <p className="text-white/90 text-lg mb-1">
                {selectedProfile.profileData.occupation}
              </p>
              <p className="text-white/80">
                {selectedProfile.profileData.location}
              </p>
            </div>
          </div>

          <div className="p-6 space-y-6">
            <div>
              <h3 className="text-lg font-semibold mb-2">About</h3>
              <p className="text-gray-600">{selectedProfile.profileData.bio}</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-2">Interests</h3>
              <div className="flex flex-wrap gap-2">
                {selectedProfile.profileData.interests.map((interest, index) => (
                  <span
                    key={index}
                    className="bg-purple-100 text-purple-600 px-3 py-1 rounded-full text-sm"
                  >
                    {interest}
                  </span>
                ))}
              </div>
            </div>

            <button
              onClick={(e) => handleChatClick(e, selectedProfile)}
              className="w-full bg-purple-600 text-white rounded-full py-3 px-6 font-semibold flex items-center justify-center gap-2 hover:bg-purple-700 transition-colors"
            >
              <MessageCircle size={20} />
              Message
            </button>
          </div>
        </motion.div>
      ) : (
        <div className="max-w-md mx-auto h-full p-4">
          <div className="flex items-center justify-between mb-6">
            <button
              onClick={handleBackClick}
              className="bg-white p-2 rounded-full shadow-sm hover:bg-gray-50 transition-colors"
            >
              <ChevronLeft size={24} className="text-gray-600" />
            </button>
            <h1 className="text-2xl font-bold">Matches</h1>
            <div className="w-8"></div> {/* Spacer for centering */}
          </div>
          
          <div className="space-y-4">
            {matches.length === 0 ? (
              <div className="text-center py-12 text-gray-500">
                <p>No matches yet</p>
                <p className="text-sm">Keep swiping to find your match!</p>
              </div>
            ) : (
              <AnimatePresence mode="popLayout">
                {matches.map((match) => (
                  <motion.div
                    key={match.id}
                    layout
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ 
                      x: -300, 
                      opacity: 0,
                      transition: { duration: 0.2 }
                    }}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={0.7}
                    onDragEnd={(e, info) => {
                      if (info.offset.x < -100) {
                        setUnmatchingId(match.id);
                        handleUnmatch(match.id);
                      }
                    }}
                    className="bg-white rounded-xl p-4 shadow-sm flex items-center gap-4 relative"
                  >
                    {/* Drag handle - entire card except interactive elements */}
                    <div className="absolute inset-0 cursor-grab active:cursor-grabbing" />

                    {/* Unmatch indicator */}
                    <motion.div
                      className="absolute inset-0 bg-red-500/10 rounded-xl flex items-center justify-start pl-4 pointer-events-none"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: match.id === unmatchingId ? 1 : 0 }}
                    >
                      <X className="text-red-500" size={24} />
                    </motion.div>

                    {/* Interactive elements on top of drag handle */}
                    <div 
                      className="w-16 h-16 rounded-full overflow-hidden cursor-pointer relative z-10"
                      onClick={(e) => handleProfileClick(e, match)}
                    >
                      {getPhotoUrl(match.profileData.photos) ? (
                        <img
                          src={getPhotoUrl(match.profileData.photos)}
                          alt={match.profileData.name}
                          className="w-full h-full object-cover"
                          onError={(e) => {
                            console.error('Image failed to load:', getPhotoUrl(match.profileData.photos));
                            e.target.src = 'https://via.placeholder.com/64?text=?';
                          }}
                        />
                      ) : (
                        <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                          <span className="text-gray-500">?</span>
                        </div>
                      )}
                    </div>
                    
                    <div className="flex-1 relative z-10">
                      <h3 className="font-semibold">{match.profileData.name}</h3>
                      <p className="text-sm text-gray-500">
                        {match.profileData.occupation}
                      </p>
                    </div>

                    <button
                      onClick={(e) => handleChatClick(e, match)}
                      className="bg-purple-100 text-purple-600 p-2 rounded-full hover:bg-purple-200 transition-colors relative z-10"
                    >
                      <MessageCircle size={20} />
                    </button>
                  </motion.div>
                ))}
              </AnimatePresence>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MatchesScreen;
