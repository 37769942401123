// ProfileScreen.jsx
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { LogOut, Trophy, Heart, ArrowLeft, Star, Clock, Users, Sliders } from 'lucide-react';

const ProfileScreen = () => {
  const { user, authenticatedFetch, updateUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [userStats, setUserStats] = useState(null);
  const [recentGames, setRecentGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [updatingPreference, setUpdatingPreference] = useState(false);
  const [error, setError] = useState(null);
  const [minAge, setMinAge] = useState(user?.min_age_preference || 21);
  const [maxAge, setMaxAge] = useState(user?.max_age_preference || 35);
  const [savingAge, setSavingAge] = useState(false);

  const fetchUserStats = async () => {
    try {
      // Add timestamp to force fresh data
      const timestamp = new Date().getTime();
      const response = await authenticatedFetch(`/api/user/stats?t=${timestamp}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch user stats');
      }

      const data = await response.json();
      setUserStats(data.stats);
      setRecentGames(data.recentGames);
      // Update age preferences from user data
      if (data.user) {
        setMinAge(data.user.min_age_preference || 21);
        setMaxAge(data.user.max_age_preference || 35);
      }
    } catch (error) {
      console.error('Failed to fetch user stats:', error);
      setError('Failed to load user stats. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreferenceChange = async (preference) => {
    setUpdatingPreference(true);
    setError(null);
    try {
      const response = await authenticatedFetch('/api/user/update-preferences', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          interested_in: preference
        })
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to update preference');
      }

      const data = await response.json();
      if (data.user) {
        updateUser(data.user);
      }
    } catch (error) {
      console.error('Failed to update preference:', error);
      setError(error.message || 'Failed to update preference. Please try again.');
    } finally {
      setUpdatingPreference(false);
    }
  };

  const handleAgeChange = async () => {
    setSavingAge(true);
    setError(null);
    try {
      const response = await authenticatedFetch('/api/user/update-preferences', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          min_age_preference: minAge,
          max_age_preference: maxAge
        })
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to update age preferences');
      }

      const data = await response.json();
      if (data.user) {
        updateUser(data.user);
      }
    } catch (error) {
      console.error('Failed to update age preferences:', error);
      setError(error.message || 'Failed to update age preferences. Please try again.');
    } finally {
      setSavingAge(false);
    }
  };

  // Handle min age change
  const handleMinAgeChange = (e) => {
    const value = parseInt(e.target.value);
    setMinAge(Math.min(value, maxAge));
  };

  // Handle max age change
  const handleMaxAgeChange = (e) => {
    const value = parseInt(e.target.value);
    setMaxAge(Math.max(value, minAge));
  };

  // Fetch stats when component mounts
  useEffect(() => {
    fetchUserStats();
  }, []);

  // Fetch stats when returning to the profile screen
  useEffect(() => {
    const refreshStats = () => {
      if (document.visibilityState === 'visible') {
        fetchUserStats();
      }
    };

    document.addEventListener('visibilitychange', refreshStats);
    return () => {
      document.removeEventListener('visibilitychange', refreshStats);
    };
  }, []);

  // Fetch when navigating back to this screen
  useEffect(() => {
    fetchUserStats();
  }, [location.key]);

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-white border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-600 via-purple-500 to-pink-500 p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <ArrowLeft
          className="text-white/90 cursor-pointer"
          size={24}
          onClick={() => navigate('/')}
        />
        <button
          onClick={handleLogout}
          className="flex items-center space-x-2 text-white/90 hover:text-white"
        >
          <LogOut size={20} />
          <span>Logout</span>
        </button>
      </div>

      {/* Profile Header */}
      <div className="flex items-center space-x-4 mb-8">
        <div className="w-20 h-20 rounded-full bg-white/20 flex items-center justify-center">
          <span className="text-3xl text-white">
            {user?.name?.charAt(0).toUpperCase() || user?.email?.charAt(0).toUpperCase() || '?'}
          </span>
        </div>
        <div>
          <h2 className="text-2xl font-bold text-white">{user?.name || 'User'}</h2>
          <p className="text-white/60">{user?.email}</p>
          <div className="flex items-center space-x-2 mt-1">
            <Trophy className="text-yellow-300" size={16} />
            <span className="text-white/80">Rating: {user?.rizzRating || 1000}</span>
          </div>
        </div>
      </div>

      {/* Preference Selection */}
      <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4 mb-8">
        <div className="flex items-center space-x-2 mb-4">
          <Users className="text-white" size={20} />
          <h3 className="text-white font-semibold">I want to chat with:</h3>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <button
            onClick={() => handlePreferenceChange('female')}
            disabled={updatingPreference}
            className={`p-4 rounded-xl flex items-center justify-center gap-2 transition-colors ${
              user?.interested_in === 'female'
                ? 'bg-pink-500 text-white'
                : 'bg-white/10 text-white/80 hover:bg-white/20'
            } ${updatingPreference ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <span>Girls</span>
            {user?.interested_in === 'female' && <Heart size={16} />}
          </button>

          <button
            onClick={() => handlePreferenceChange('male')}
            disabled={updatingPreference}
            className={`p-4 rounded-xl flex items-center justify-center gap-2 transition-colors ${
              user?.interested_in === 'male'
                ? 'bg-blue-500 text-white'
                : 'bg-white/10 text-white/80 hover:bg-white/20'
            } ${updatingPreference ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <span>Guys</span>
            {user?.interested_in === 'male' && <Heart size={16} />}
          </button>
        </div>
        {error && (
          <p className="mt-2 text-red-400 text-sm text-center">{error}</p>
        )}
      </div>

      {/* Age Range Preferences */}
      <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4 mb-8">
        <div className="flex items-center space-x-2 mb-4">
          <Sliders className="text-white" size={20} />
          <h3 className="text-white font-semibold">Age Range: {minAge} - {maxAge}</h3>
        </div>
        <div className="space-y-4">
          <div>
            <label className="block text-white text-xs mb-1">Minimum Age</label>
            <input
              type="range"
              min="18"
              max="100"
              value={minAge}
              onChange={handleMinAgeChange}
              onMouseUp={handleAgeChange}
              onTouchEnd={handleAgeChange}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              disabled={savingAge}
            />
          </div>
          <div>
            <label className="block text-white text-xs mb-1">Maximum Age</label>
            <input
              type="range"
              min="18"
              max="100"
              value={maxAge}
              onChange={handleMaxAgeChange}
              onMouseUp={handleAgeChange}
              onTouchEnd={handleAgeChange}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              disabled={savingAge}
            />
          </div>
        </div>
        {error && (
          <p className="mt-2 text-red-400 text-sm text-center">{error}</p>
        )}
      </div>

      {/* Stats Cards */}
      <div className="grid grid-cols-2 gap-4 mb-8">
        <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4">
          <div className="flex items-center space-x-2 mb-2">
            <Star className="text-yellow-300" size={20} />
            <span className="text-white font-semibold">Best Score</span>
          </div>
          <p className="text-3xl font-bold text-white">{user?.bestScore?.toFixed(1) || '0.0'}</p>
        </div>
        <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4">
          <div className="flex items-center space-x-2 mb-2">
            <Heart className="text-pink-300" size={20} />
            <span className="text-white font-semibold">Games</span>
          </div>
          <p className="text-3xl font-bold text-white">{user?.gamesPlayed || 0}</p>
        </div>
      </div>

      {/* Mode Stats */}
      <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4 mb-8">
        <h3 className="text-white font-semibold mb-4">Game Modes</h3>
        <div className="space-y-4">
          {Object.entries(userStats?.modeStats || {}).map(([mode, stats]) => (
            <div key={mode} className="flex justify-between items-center">
              <div>
                <p className="text-white capitalize">{mode}</p>
                <p className="text-white/60 text-sm">Best: {stats.bestScore.toFixed(1)}</p>
              </div>
              <div className="text-right">
                <p className="text-white">{stats.games} games</p>
                <p className="text-white/60 text-sm">Avg: {stats.avgScore.toFixed(1)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Recent Games */}
      <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4">
        <h3 className="text-white font-semibold mb-4">Recent Games</h3>
        {recentGames.length > 0 ? (
          <div className="space-y-4">
            {recentGames.map((game, index) => (
              <div key={index} className="flex justify-between items-center border-b border-white/10 pb-4">
                <div>
                  <p className="text-white">{game.characterId}</p>
                  <p className="text-white/60 text-sm">{formatDate(game.createdAt)}</p>
                </div>
                <div className="text-right">
                  <p className="text-white font-bold">{game.score.toFixed(1)}</p>
                  <p className={`text-sm ${game.ratingChange >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                    {game.ratingChange >= 0 ? '+' : ''}{game.ratingChange} Rating
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-white/60 text-center py-4">No recent games</p>
        )}
      </div>
    </div>
  );
};

export default ProfileScreen;
